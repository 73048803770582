<template>
	<div class="fill-height fill-width">
		<v-container
			fluid 
			class="pa-0" 
			style="height: calc(100vh - 60px);" 
		>
			<div
				class="px-10 pt-10 fill-height fill-width"
			>
				<v-row no-gutters>
					<v-col cols="12" md="auto" class="title mb-1">
						<span
							@click="$router.push('/new-order')"
						>
							Vehicle Information
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span
							@click="$router.push('/new-order/select-seat')"
						>
							Select Seat Model
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="backToCustomizationPage()">Customize Seat</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span class="black--text">Options</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col 
						cols="12"
						md="9"
					>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="5"
								class="mr-5 my-3"
							>
								<span>Airbag (FB-Front Back)</span>
								<p class="mt-3">
									<span
										v-for="(item,i) in selectedSeatModel.airbag_styles_fbs"
										:key="i"
										class="font-weight-black" 
									>
										- {{ item.name }} <br>
									</span>
								</p>
								<v-divider></v-divider>
							</v-col>
							<v-col 
								cols="6"
								md="5"
								class="my-3"
							>
								<span>Logo Seam Color Choice</span>
								<p>
									<v-select
										v-model="selectedOrderOptions.logoSeamColor"
										filled rounded
										dense hide-details
										color="secondary"
										placeholder="Select Logo Seam Color"
										class="radius-10 pr-0"
										:items="optionLogoSeamColors"
										item-text="name" return-object
										:disabled="orderCustomizations.logo ? false : true"
									>
										<template slot="selection" slot-scope="{ item }">
											<div
												v-ripple
												style="height: 25px;width: 25px;"
												class="mr-3"
												:style="'background:'+item.rgba"
											></div>
											<div>
												{{ item.name }}
											</div>	
										</template>
										<template slot="item" slot-scope="{ item}">
											<div
												v-ripple
												style="height: 25px;width: 25px;"
												class="mr-3"
												:style="'background:'+item.rgba"
											></div>
											<div>
												{{ item.name }}
											</div>	
										</template>
									</v-select>
								</p>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="5"
								class="mr-5 my-3"
							>
								<span>Airbag (DT-Door Trim)</span>
								<p class="mt-3">
									<span
										v-for="(item,i) in selectedSeatModel.airbag_styles_dts"
										:key="i"
										class="font-weight-black" 
									>
										- {{ item.name }} <br>
									</span>
								</p>
								<v-divider></v-divider>
							</v-col>
							<v-col 
								cols="6"
								md="5"
								class="my-3"
							>
								<span>Driver Seat</span>
								<span class="error--text"> *</span>
								<p>
									<v-select
										v-model="selectedOrderOptions.driverSeat"
										filled rounded
										dense hide-details
										color="secondary"
										placeholder="Select Driver Seat"
										class="radius-10 pr-0"
										:items="optionDriverSeats"
										item-text="name" return-object
									></v-select>
								</p>
								
								<v-divider></v-divider>
							</v-col>					
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="5"
								class="mr-5 my-3"
							>
								<span>Airbag (Others)</span>
								<p class="mt-3">
									<span
										v-for="(item,i) in selectedSeatModel.dt_styles"
										:key="i"
										class="font-weight-black" 
									>
										- {{ item.name }} <br>
									</span>
								</p>
								<v-divider></v-divider>
							</v-col>
							<v-col 
								v-if="!$authUser.isCustomer() && !$authUser.isShowroom()"
								cols="6"
								md="5"
								class="mr-5 my-3"
							>
								<span>Components</span>
								<p>
									<v-autocomplete
										v-model="selectedOrderOptions.component"
										filled rounded
										dense hide-details
										color="secondary"
										placeholder="None"
										class="radius-10 pr-0"
										:items="optionComponents"
										item-text="name" return-object
									></v-autocomplete>
								</p>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row
							v-if="$authUser.isBdu()"
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="5"
								class="mr-5 my-3"
							>
								<span>Agent/Consumer</span>
								<v-radio-group
									v-model="selectedOrderOptions.userType"
									row dense
									@change="clearUserList"
								>
									<v-radio
										label="Agent" dense
										:value="userConst.ROLE_AGENT"
									></v-radio>
									<v-radio
										label="Consumer" dense
										:value="userConst.ROLE_CONSUMER"
									></v-radio>
								</v-radio-group>
								<p class="mt-0">
									<v-autocomplete
										v-model="selectedOrderOptions.userInfo"
										filled rounded
										dense hide-details
										color="secondary"
										placeholder="Search User"
										:search-input.sync="search_input_"
										class="radius-10 pr-0"
										:items="optionUserList"
										:loading="model_loading_"
										clearable
										item-value="id"
										item-text="name" return-object
										@click:clear="clearUserList()"
									></v-autocomplete>
								</p>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row 
							no-gutters
							class="overflow-auto pt-3"
						>
							<v-col 
								cols="12"
							>
								<span>Remark</span>
								<br>
								<v-textarea
									v-model="selectedOrderOptions.remarks"
									filled
									rows="2"
									row-height="3"
									label="Fill Remark"
									class="mt-3"
									no-resize
								></v-textarea>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				
				<v-row justify="end" class="pt-10">
					<v-col cols="12" md="3">
						<v-btn
							block
							depressed
							:disabled="buttonOff"
							color="primary"
							class="text-capitalize secondary--text radius-10"
							@click="nextStage()"
						>
							Continue - View 2D
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { searchMixin } from "@/mixins/SearchMixin"
import { mapGetters, mapActions } from "vuex"
import userConst from '@/configs/userConst'

const COLOR_GROUP_LOGO = "9"
const DEFAULT_LOGO_SEAM_COLOR = 376

export default {
	components:{

	},
	mixins: [
		resourceMixin,
		searchMixin,
		errorHandlerMixin
	],
	props:{
	},
	data(){
		return {
			optionLogoSeamColors: [],
			optionDriverSeats: [],
			optionComponents: [],
			optionUserList: [],
			selectedOrderOptions: {
				remarks: null,
				logoSeamColor: null,
				driverSeat: null,
				component: null,
				userType: userConst.ROLE_AGENT,
				userInfo: null,
			},
			userConst: userConst,
		}
	},
	computed:{
		...mapGetters(["orderCustomizations", "partSpecs", "selectedSeatModel", "orderOptions"]),
		buttonOff(){
			if(!this.$authUser.isBdu())
				return this.selectedOrderOptions.driverSeat == null
			else
				return this.selectedOrderOptions.driverSeat == null || this.selectedOrderOptions.userInfo == null
		},
	},
	activated() {
		if(Object.keys(this.orderCustomizations).length === 0)
			this.backToCustomizationPage()
			
		// this.init()
		this.setIsCustomization(true)
	},
	created(){
		this.init()
	},
	mounted(){
		this.restoreOrderOptions()
	},
	methods:{
		...mapActions(["setOrderOptions", "setIsCustomization"]),
		init()
		{
			this.getAllRefs_([
				this.getLogoSeamColor(),
				this.getDriverSeat(),
				this.getComponent()
			]).then(()=>{
				this.assignDefaultLogoColor()
			})
		},
		searchData_(){
			this.searchUserList()
		},
		previousStage()
		{
			this.$router.push("/new-order/customization")
		},
		nextStage()
		{
			this.setOrderOptions(this.selectedOrderOptions)
			this.$router.push("/new-order/view-2d")
		},
		async getLogoSeamColor(){
			let payload = {
				type_id: COLOR_GROUP_LOGO,
				itemsPerPage: 100,
				is_archived: 0,
			}
			await this.$api.getColorList(payload).then((res)=>{
				let { data } = res.data.result
				data.forEach((item)=>{
					item.rgba = this.computedRGBA(item)
				})
				this.optionLogoSeamColors = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		computedRGBA(item){
			return `rgba(${item.r}, ${item.g}, ${item.b}, ${item.a});`
		},
		getDriverSeat(){
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getDriverSeatList(payload).then((res)=>{
				let { data } = res.data.result
				this.optionDriverSeats = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getComponent(){
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getComponentList(payload).then((res)=>{
				let { data } = res.data.result
				this.optionComponents = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		assignDefaultLogoColor()
		{
			if(!this.selectedOrderOptions.logoSeamColor || !this.orderCustomizations.logo)
				this.selectedOrderOptions.logoSeamColor = this.optionLogoSeamColors.find((item) => item.id == DEFAULT_LOGO_SEAM_COLOR)
		},
		backToCustomizationPage()
		{
			this.setIsCustomization(true)
			this.$router.push('/new-order/customization')
		},
		clearUserList()
		{
			this.selectedOrderOptions.userInfo = null
			this.optionUserList = []
		},
		searchUserList()
		{
			let payload = {
				name: this.search_input_ ?? null,
				role_id: this.selectedOrderOptions.userType,
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.model_loading_ = true
			this.$api.getUserList(payload).then((res)=>{
				let { data } = res.data.result
				this.optionUserList = data
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		restoreOrderOptions()
		{
			if(this.orderOptions)
			{
				this.selectedOrderOptions = this.orderOptions
			}
		}

	}
}
</script>
<style>
body, html {
	padding: 0;
	margin: 0;
	width: 100%;
	min-height: 100vh;
}
</style>
